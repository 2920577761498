import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import GraphQLErrorList from "../components/Blog/graphql-error-list";

import CallToAction from "../components/Repeating/CTA";
import ServiceSection1 from "../components/Service/ServiceSection1";
import ServiceSection2 from "../components/Service/ServiceSection2";
// import Testimonials from "../components/Repeating/Testimonials";
import WhyUs from "../components/Repeating/WhyUs";
import HarborViewLifestyle from "../components/Repeating/HarborViewLifestyle";
// import RecentPosts from "../components/Repeating/RecentPosts";

const EnrichingExperiencesPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout siteSettings={data.siteSettings} contactInfo={data.contactInfo}>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  return (
    <Layout siteSettings={data.siteSettings} contactInfo={data.contactInfo}>
      <SearchEngineOptimization
        title="Senior Living | Enriching Experiences | MesaView"
        description="MesaView Senior Assisted Living residents have access to a variety of events and activities that help them embrace life every day. Contact us to learn more."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <ServiceSection1
        sectionTitle={data.sanityEnrichingexperiences.title}
        sectionDesc={data.sanityEnrichingexperiences.description}
        contents={data.sanityEnrichingexperiences}
      />
      <ServiceSection2
        title1={data.sanityEnrichingexperiences.title1}
        description1={data.sanityEnrichingexperiences._rawDescription1}
        title2={data.sanityEnrichingexperiences.title2}
        description2={data.sanityEnrichingexperiences._rawDescription2}
        title3={data.sanityEnrichingexperiences.title3}
        description3={data.sanityEnrichingexperiences._rawDescription3}
        sectionNumber={5}
      />
      <HarborViewLifestyle
        shadow={true}
        className="mb-14  bg-gray-50  p-4 md:mb-24 lg:p-24"
        hide={4}
      />
      <WhyUs />
      {/* <Testimonials />
      <RecentPosts /> */}
      <CallToAction phonenumber={data.contactInfo.phonenumber} />
    </Layout>
  );
};

export const query = graphql`
  {
    contactInfo: sanityContactinfo {
      title
      description
      phonenumber
      email
      address
      license
      bookingurl
    }
    siteSettings: sanitySiteSettings {
      title
      description
      author
      headerlogo {
        asset {
          gatsbyImageData
        }
      }
      footerlogo {
        asset {
          gatsbyImageData
        }
      }
      facebookurl
      twitterurl
      privacypolicy
    }

    sanityEnrichingexperiences {
      title
      description
      bgimage {
        asset {
          gatsbyImageData
        }
      }
      mobilebgimage {
        asset {
          gatsbyImageData
        }
      }
      title1
      _rawDescription1
      title2
      _rawDescription2
      title3
      _rawDescription3
    }
  }
`;

export default EnrichingExperiencesPage;
